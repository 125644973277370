

export const BASE_SEO_PROPERTIES:Partial<UseSeoMetaInput> = Object.freeze({
  ogImageAlt: "Newtify logo",
  ogImageHeight: "630",
  ogImageWidth: "1200",
  ogImageType: "image/png",
  ogImage: "/og-image.png",
  ogType: "website",
  ogUrl: "newtify.io",
  ogSiteName: "Newtify",
  twitterImage: {
    url: "newtify.io/og-image.png",
    alt: "Newtify logo",
    type: "image/png",
    width: 1200,
    height: 630,
  },
});
